import * as React from "react"
import { Link } from "gatsby"
import BertJonkVan from '../../images/11-bakwagen-20m3.webp'
import ReactMarkDown from 'react-markdown'
//svg's
import car from '../../images/svg/car.svg'
import passengerVan from '../../images/svg/passengerVan.svg'
import heavyTruck from '../../images/svg/heavyTruck.svg'
import coolingTruck from '../../images/svg/coolingTruck.svg'
import deliveryVan from '../../images/svg/deliveryVan.svg'

const OpeningHoursInformation = () => (
    <div className="opening-hours__information">
        <div className="title">
            <h2>Openingstijden</h2>
        </div>
        <div className="day">
            <div><p>Maandag</p></div>
            <div className="times"><p>08:00 - 18:00</p></div>
        </div>
        <div className="day">
            <div><p>Dinsdag</p></div>
            <div className="times"><p>08:00 - 18:00</p></div>
        </div>
        <div className="day">
            <div><p>Woensdag</p></div>
            <div className="times"><p>08:00 - 18:00</p></div>
        </div>
        <div className="day">
            <div><p>Donderdag</p></div>
            <div className="times"><p>08:00 - 18:00</p></div>
        </div>
        <div className="day">
            <div><p>Vrijdag</p></div>
            <div className="times"><p>08:00 - 18:00</p></div>
        </div>
        <div className="day">
            <div><p>Zaterdag (Purmerend en Zwaag)</p></div>
            <div className="times"><p>08:00 - 17:00</p></div>
        </div>
        <div className="day">
            <div><p>Zaterdag (Oude Meer / Schiphol)</p></div>
            <div className="times"><p>08:00 - 12:00</p></div>
        </div>
        <div className="day">
            <div><p>Zondag</p></div>
            <div className="times"><p>gesloten</p></div>
        </div>
    </div>
)

const OpeningHoursProducts = () => (
    <div className="opening-hours__side-wrapper">
        <div className="title">
            <h2>Producten</h2>
        </div>
        <div className="opening-hours__side">
            <Link to='/auto-huren'>
                <div className="thumbnail__product">
                    <div>
                        <img src={car} alt="Auto huren" title="auto huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                        <br></br>
                        Auto
                    </div>
                </div>
            </Link>
            <Link to='/bestelauto-huren'>
                <div className="thumbnail__product">
                    <div>
                        <img src={deliveryVan} alt="Bestelauto huren" title="bestelauto huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                        <br></br>
                        Bestelauto
                    </div>
                </div>
            </Link>
            <Link to='/personenbus-huren'>
                <div className="thumbnail__product">
                    <div>
                        <img src={passengerVan} alt="Personenbus huren" title="personenbus huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                        <br></br>
                        Personenbus
                    </div>
                </div>
            </Link>
            <Link to='/busje-huren'>
                <div className="thumbnail__product">
                    <div>
                        <img src={deliveryVan} alt="Busje huren" title="busje huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                        <br></br>
                        Busje
                    </div>
                </div>
            </Link>
            <Link to='/vrachtwagen-huren'>
                <div className="thumbnail__product">
                    <div>
                        <img src={heavyTruck} alt="Vrachtwagen huren" title="vrachtwagen huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                        <br></br>
                        Vrachtwagen
                    </div>
                </div>
            </Link>
            <Link to='/koelwagen-huren'>
                <div className="thumbnail__product">
                    <div>
                        <img src={coolingTruck} alt="Koelwagen huren" title="koelwagen huren" width="48" height="48" style={{ paddingBottom: 4 }} className='img-fluid'></img>
                        <br></br>
                        Koelwagen
                    </div>
                </div>
            </Link>
        </div>
        <div>
            <img src={BertJonkVan} alt="van" className='img-fluid'></img>
        </div>
    </div>
)

const OpeningHoursSideLanding = (props) => (
    <div className="opening-hours__side-wrapper">
        {props.information && 
            <ReactMarkDown>{props.information.data.sideInfo}</ReactMarkDown>
        }
    </div>
)

const OpeningHoursHome = () => (
    <div className="opening-hours__background">
        <div className="container">
            <div className="opening-hours">
                <OpeningHoursInformation />
                <OpeningHoursProducts />
            </div>
        </div>
    </div>
)

const OpeningHoursLanding = (props) => (
    <div className="opening-hours__background">
        <div className="container">
            <div className="opening-hours">
                <OpeningHoursInformation />
                <OpeningHoursSideLanding information={props.information}></OpeningHoursSideLanding>
            </div>
        </div>
    </div>
)

export { OpeningHoursHome, OpeningHoursLanding }