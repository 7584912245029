import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Navbar, Nav } from 'react-bootstrap'
import { useDispatch } from "react-redux"
import { setShow } from "../../redux/modalSlice"

const Header = ({ siteTitle }) => {
  const dispatch = useDispatch();

  const handleOpeningHoursClick = () => {
    dispatch(setShow(true))
  }

  return (
    <header>
      <div className="background-light">
        <div className="navbar-top container">
          {/* <div className="navbar-top__reviews">
            <div className="navbar-top__stars">
              <FontAwesomeIcon icon={['fas', 'star']} color="orange" />
              <FontAwesomeIcon icon={['fas', 'star']} color="orange" />
              <FontAwesomeIcon icon={['fas', 'star']} color="orange" />
              <FontAwesomeIcon icon={['fas', 'star']} color="orange" />
              <FontAwesomeIcon icon="star" color="gray" />
            </div>
            <div>9.0 / 10 | 612 klantervaringen</div>

          </div> */}
          <div style={{ cursor: 'pointer' }} onClick={handleOpeningHoursClick}>
            Gewijzigde openingstijden v.a. 2 september a.s.
          </div>

          <div className="navbar-top__contact">
            <div>
              <FontAwesomeIcon icon="envelope" />
              <span style={{ marginLeft: 12 }}><a href="mailto:info@bertjonk.nl">info@bertjonk.nl</a></span>
            </div>
            <div>
              <FontAwesomeIcon icon="phone" flip="horizontal" />
              <span style={{ marginLeft: 12 }}><a href="tel:088-3745900">088-3745900</a></span>
            </div>
          </div>
        </div>
      </div>

      <div className="background-light">
        <div className="container">
          <Navbar expand="md" className="navbar">
            <Navbar.Brand>
              <Link to="/">
                <StaticImage src='../../images/logo.jpg' alt='logo' className="navbar-logo" style={{ width: 180 }} />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Nav className="nav-items">
                <Nav.Item>
                  <div className="dropdown">
                    <div className="nav-link dropbtn">Wagenpark</div>
                    <div className="dropdown-content">
                      <div>
                        <Link to="/bestelauto-huren" activeClassName="nav-active">Bestelauto Huren</Link>
                      </div>
                      <div>
                        <Link to="/busje-huren" activeClassName="nav-active">Busje Huren</Link>
                      </div>
                      <div>
                        <Link to="/auto-huren" activeClassName="nav-active">Personenauto Huren</Link>
                      </div>
                      <div>
                        <Link to="/vrachtwagen-huren" activeClassName="nav-active">Vrachtwagen Huren</Link>
                      </div>
                      <div>
                        <Link to="/koelwagen-huren" activeClassName="nav-active">Koelwagen Huren</Link>
                      </div>
                      <div>
                        <Link to="/personenbus-huren" activeClassName="nav-active">Personenbus Huren</Link>
                      </div>
                      <div>
                        <Link to="/autoambulance-huren" activeClassName="nav-active">Autoambulance Huren</Link>
                      </div>
                      <div>
                        <Link to="/rolstoelbus-huren" activeClassName="nav-active">Rolstoelbus Huren</Link>
                      </div>
                      <div>
                        <Link to="/pick-up-huren" activeClassName="nav-active">Pickup Huren</Link>
                      </div>
                      <div>
                        <Link to="/bakwagen-huren" activeClassName="nav-active">Bakwagen Huren</Link>
                      </div>
                    </div>
                  </div>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/shortlease" className="nav-link" activeClassName="nav-active">Shortlease</Link>
                </Nav.Item>
                <Nav.Item>
                  <Link to="/reclamevrije-auto-huren" className="nav-link" activeClassName="nav-active">Reclamevrij</Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Link to="/aanbiedingen/wintersport" className="nav-link" activeClassName="nav-active">Wintersport Aanbiedingen</Link>
                </Nav.Item> */}
                {/* <Nav.Item>
                  TODO: Readd about us
                  <Link to="/over-ons" className="nav-link" activeClassName="nav-active">Over Ons</Link>
                </Nav.Item> */}
                <Nav.Item>
                  <Link to="/blogs" className="nav-link" activeClassName="nav-active">Blogs</Link>
                </Nav.Item>
                <Nav.Item>
                  <div className="dropdown">
                    <Link to='/verhuurvestigingen' activeClassName="nav-active" ><div className="nav-link dropbtn" >Locaties</div></Link>
                    <div className="dropdown-nogrid">
                      <div>
                        <Link to="/verhuurvestigingen/oude-meer-schiphol" activeClassName="nav-active">Oude Meer / Schiphol</Link>
                      </div>
                      <div>
                        <Link to="/verhuurvestigingen/purmerend" activeClassName="nav-active">Purmerend</Link>
                      </div>
                      <div>
                        <Link to="/verhuurvestigingen/zwaag-hoorn" activeClassName="nav-active">Zwaag / Hoorn</Link>
                      </div>
                    </div>
                  </div>
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}



export default Header
