import React, { useEffect, useState } from "react"
import Other from "../../../images/svg/other.png"
import { compare, getDisplayPriceOfOption } from "./HelperFunctions"

const SelectOtherOptions = ({
  optionalOptions,
  totalDays,
  handleCheckBox,
  includeVat,
}) => {
  const [populairFilter, setPopulairFilter] = useState([])
  const [optionFilter, setOptionFilter] = useState([])
  const [showExtras, setShowExtras] = useState(false)
  const [showExtraText, setShowExtraText] = useState(true)

  useEffect(() => {
    const populairFilter = optionalOptions
      ? optionalOptions.filter(option => option.category === "Populair")
      : []
    setPopulairFilter(populairFilter)

    const optionFilter = optionalOptions
      ? optionalOptions.filter(
          option =>
            option.category !== "Verzekering (Overig)" &&
            option.category !== "Wintersport" &&
            option.category !== "Voertuig" &&
            option.category !== "Populair" &&
            option.category !== "Kassakoopjes" &&
            !option.category.toLowerCase().includes("inzittendeverzekering")
        )
      : []
    setOptionFilter(optionFilter)
  }, [optionalOptions, totalDays, includeVat])


  const toggleExtras = () => {
    setShowExtras(true)
    setShowExtraText(false)
  }

  const hideExtras = () => {
    setShowExtras(false)
    setShowExtraText(true)
  }

  return (
    <div className="extra-wrapper">
      {optionalOptions && showExtraText && (
        <span onClick={toggleExtras} className="mouse">
          <div className="extra-wrapper__title">
            <img src={Other} alt="Overige Opties"></img>
            <h3 className="bold">Toon meer opties {">>"}</h3>
          </div>
        </span>
      )}
      {showExtras && (
        <>
          <div className="extra-wrapper__title">
            <img src={Other} alt="Overige Opties"></img>
            <h3>Overige Opties</h3>
          </div>
          {populairFilter.length > 0 && (
            <>
              <h3 style={{ paddingTop: 12 }}>Voordeelpakketten</h3>
              <div className="extrabox-grid">
                {populairFilter.sort(compare).map(option => (
                  <div key={option.optionId}>
                    <input
                      type="checkbox"
                      id={option.optionId}
                      name="optionId"
                      value={option.optionId}
                      onChange={event =>
                        handleCheckBox("optionalOptions", event)
                      }
                    />
                    <label htmlFor={option.optionId}>
                      <div className="extrabox">
                        <h3>
                          {option.description} (€
                            {getDisplayPriceOfOption(option, totalDays, includeVat)}
                        </h3>
                        <p className="dark-gray">
                          {option.labelMarkup.replace(/(<([^>]+)>)/gi, "")}
                        </p>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            </>
          )}
          <>
            <h3 style={{ paddingTop: 12 }}>Overig</h3>
            <div className="extrabox-grid">
              {optionFilter.sort(compare).map(option => (
                <div key={option.optionId}>
                  <input
                    type="checkbox"
                    id={option.optionId}
                    name="optionId"
                    value={option.optionId}
                    onChange={event => handleCheckBox("optionalOptions", event)}
                  />
                  <label htmlFor={option.optionId}>
                    <div className="extrabox">
                      <h3>
                        {option.description} (€
                        {includeVat !== "yes"
                          ? ((option.price.value * totalDays) / 1.21)
                              .toFixed(2)
                              .replace(".", ",")
                          : (option.price.value * totalDays)
                              .toFixed(2)
                              .replace(".", ",")}
                        )
                      </h3>
                      <p className="dark-gray">
                        {option.labelMarkup.replace(/(<([^>]+)>)/gi, "")}
                      </p>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </>
          <span onClick={hideExtras}>
            <h3 className="bold mouse" style={{ margin: "10px 0" }}>
              {"<<"} Extra Opties Verbergen
            </h3>
          </span>
        </>
      )}
    </div>
  )
}

export default SelectOtherOptions
